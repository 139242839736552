import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from "axios";
import { Box } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { BaseUrl } from "../../Constants";



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    maxWidth: 100,
    wordWrap: "break-word",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {},
  tableContainer: {
    marginTop: 80,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function Devices() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [all, setAll] = React.useState([]);
  const [assigned, setAssigned] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [total, setTotal] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteRow = (id) => {
    if (window.confirm("do you want to delete this Device?")) {
      let user_id = localStorage.getItem("user_id");
      if (user_id) {
        axios
          .post(BaseUrl+"admin/deletedevice", {
            user_id,
            id
          })
          .then(function (res) {
            if (res.data.status) {
              
              getData();
            } else {
            }
          })
          .catch(function (error) {});
      }
    }
  };

  const activeRow = (id,active) => {
    if (window.confirm(`do you want to ${active?"Activate":"Deactivate"} this Device?`)) {
    let user_id = localStorage.getItem("user_id");
      if (user_id) {
        axios
          .post(BaseUrl+"admin/activedevice", {
            user_id,
            id,
            active
          })
          .then(function (res) {
            if (res.data.status) {
              
              getData();
            } else {
            }
          })
          .catch(function (error) {});
      }
    }
  }

  const getData = () => {
    let user_id = localStorage.getItem("user_id");
    if (user_id) {
      axios
        .get(BaseUrl+"admin/getunassigned", {
          params: { user_id },
        })
        .then(function (res) {
          if (res.data.status) {
            setRows(res.data.devices);
            
            axios
              .get(BaseUrl + "admin/getassigned", {
                params: { user_id },
              })
              .then(function (res) {
                if (res.data.status) {
                  setAssigned(res.data.devices);

              axios
              .get(BaseUrl + "admin/getdevices", {
                params: { user_id },
              })
              .then(function (res) {
                if (res.data.status) {
                  setAll(res.data.devices);
                  setTotal(res.data.devices.length);
                } else {
                }
              })
              .catch(function (error) {
                console.log(error);
              });
                } else {
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      
      
      
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.tableContainer}>
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
      <Typography variant="h4" style={{ marginBottom: 20 }}>
        Devices
      </Typography>
      <div>
        <h5 style={{marginRight:50}}>Total Devices: {total}</h5>
      </div>
      </div>

      <TableContainer component={Paper}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label="All" />
        <Tab label="Unassigned" />
        <Tab label="Assigned" />
        
      </Tabs>


      <TabPanel value={value} index={0}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Device Name</StyledTableCell>
                <StyledTableCell>IMEI</StyledTableCell>
                <StyledTableCell>Active</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {all.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.device_name}</StyledTableCell>
                  <StyledTableCell>{row.imei}</StyledTableCell>
                  <StyledTableCell>{row.isActive==1?"Yes":"No"}</StyledTableCell>
                  <StyledTableCell
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {row.isActive?
                    <Tooltip title="deactivate">
                      <IconButton onClick={() => activeRow(row.id,0)}>
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>:
                    <Tooltip title="activate">
                    <IconButton onClick={() => activeRow(row.id,1)}>
                      <CheckIcon color="primary" />
                    </IconButton>
                    </Tooltip>}
                    <Tooltip title="Delete">
                      <IconButton onClick={() => deleteRow(row.id)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!all.length&&<StyledTableRow style={{width:"100%"}}><div style={{margin:10,textAlign:"center"}}>No Devices Found</div></StyledTableRow>}
              
            </TableBody>
          </Table>
        </TabPanel>

      <TabPanel value={value} index={1}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Device Name</StyledTableCell>
                <StyledTableCell>IMEI</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.device_name}</StyledTableCell>
                  <StyledTableCell>{row.imei}</StyledTableCell>
                  <StyledTableCell
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {row.isActive?
                    <Tooltip title="deactivate">
                      <IconButton onClick={() => activeRow(row.id,0)}>
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>:
                    <Tooltip title="activate">
                    <IconButton onClick={() => activeRow(row.id,1)}>
                      <CheckIcon color="primary" />
                    </IconButton>
                    </Tooltip>}
                    <Tooltip title="Delete">
                      <IconButton onClick={() => deleteRow(row.id)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!rows.length&&<StyledTableRow style={{width:"100%"}}><div style={{margin:10,textAlign:"center"}}>No Devices Found</div></StyledTableRow>}
            </TableBody>
          </Table>
        </TabPanel>


        <TabPanel value={value} index={2}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Device Name</StyledTableCell>
                <StyledTableCell>email</StyledTableCell>
                <StyledTableCell>IMEI</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assigned.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.device_name}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>{row.imei}</StyledTableCell>
                  <StyledTableCell
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {row.isActive?
                    <Tooltip title="deactivate">
                      <IconButton onClick={() => activeRow(row.id,0)}>
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>:
                    <Tooltip title="activate">
                    <IconButton onClick={() => activeRow(row.id,1)}>
                      <CheckIcon color="primary" />
                    </IconButton>
                    </Tooltip>
                    }
                    <Tooltip title="Delete">
                      <IconButton onClick={() => deleteRow(row.id)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!assigned.length&&<StyledTableRow style={{width:"100%"}}><div style={{margin:10,textAlign:"center"}}>No Devices Found</div></StyledTableRow>}
            </TableBody>
          </Table>
        </TabPanel>
      </TableContainer>
    </div>
  );
}
