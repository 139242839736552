import React from "react";
import { TextField, Typography, Button, } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { BaseUrl } from "../../Constants";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  addmargin: {
    marginTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#37698E",
    "&:hover": {
      backgroundColor: "#37698E",
    },
  },
}))(Button);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AssignDevice = () => {
  const [device, setDevice] = React.useState("");
  const [user, setUser] = React.useState("");
  const [error, setError] = React.useState("");
  const [devices, setDevices] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [input, setInput] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [location, setLocation] = React.useState("");
  const classes = useStyles();

  React.useEffect(() => {
    let user_id=localStorage.getItem('user_id')
      axios
          .get(BaseUrl + "admin/getunassigned", {
              params: { user_id }
          })
          .then(function (res) {
              if (res.data.status) {
                  setDevices(res.data.devices)
              } else {
              }
          })
          .catch(function (error) {
              console.log(error);
          });

      axios
          .get(BaseUrl + "admin/getusers", {
              params: { user_id }
          })
          .then(function (res) {
              if (res.data.status) {
                  setUsers(res.data.users)
              } else {
              }
          })
          .catch(function (error) {
              console.log(error);
          });
  }, []);

  const handleClick = () => {
    setError("");
    if (!user || !device||!location) {
      setError("Please fill all fields");
    } else {

    let id=null,device_id=null;

    //check device
    devices.map((d)=>{
        if(device==d.imei)
            device_id=d.id;
    })
    if(!device_id)
     setError("Device Not Found")

    //check user
    users.map((u)=>{
        if(user===u.email)
            id=u.user_id;
    })
    if(!id)
        setError("User Not Found")

    
    if(device_id && id&&location){
      let user_id = localStorage.getItem("user_id");
      if (user_id) {
            axios
              .post(BaseUrl+"admin/assigndevice", {
                id,
                device_id,
                user_id,
                location
              })
              .then(function (res) {
                if (res.data.status) {
                  setOpen(true);
                  setDevice("");
                  setUser("");
                  setLocation("")
                } else {
                }
              })
              .catch(function (error) {
                console.log(error);
              });
      }
    }
    }
  };


  return (
    <div>
      <div className={classes.toolbar} />
      <Typography variant="h4">Assign Devices</Typography>
      <div>
        {loading ? (
          <div style={{ flex: 1, padding: 20 }}>
            <Autocomplete
              id="combo-box-demo"
              freeSolo
              onInputChange={(e, v) => {
                setDevice(v);
              }}
              value={device}
              options={devices.map((option) => option.imei.toString())}
              className={classes.addmargin}
              renderInput={(params) => (
                <TextField {...params} label="Device Name" variant="outlined" />
              )}
            />

            <Autocomplete
              id="combo-box-demo1"
              freeSolo
              onInputChange={(e, v) => {
                setUser(v);
              }}
              value={user}
              options={users.map((option) => option.email)}
              className={classes.addmargin}
              renderInput={(params) => (
                <TextField {...params} label="User Email" variant="outlined" />
              )}
            />
            <TextField
              className={classes.addmargin}
              label="Location*"
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <Typography color="error">{error}</Typography>
            <ColorButton
              className={classes.addmargin}
              variant="contained"
              onClick={handleClick}
              type="submit"
            >
              Submit
            </ColorButton>
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={() => setOpen(false)}
            >
              <Alert onClose={() => setOpen(false)} severity="success">
                Device Assigned
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <div>Loading Failed</div>
        )}
      </div>
    </div>
  );
};
export default AssignDevice;

