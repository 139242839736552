import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, Area, AreaChart } from 'recharts';
import { Button, Typography, Grid } from '@material-ui/core';
import bg from '../../../image/flowmeter.png'
import axios from 'axios'
import {BaseUrl} from "../../../Constants"


const CustomTooltip = ({ active, payload, label }) => {
    
    if (active && payload && payload.length) {
      return (
        <div style={{background:"white",border:"solid #dedede 2px",width:150,padding:5}}>
          <p >{`Time : ${label} `}</p>
          <p style={{color:"#8884d8"}}>{"Flow Rate :"+payload[0].value}</p>
          <p >{payload[0].payload.live==1?"Live":"Offline"}</p>
          <p >{"Date:"+payload[0].payload.date}</p>
        </div>
      );
    }
  
    return null;
  };


export default function Graph(props) {
    const theme = useTheme();


    const [data, setData] = React.useState([]);
    const [current, setCurrent] = React.useState('N/A')
    const [total, setTotal] = React.useState('N/A')

    

    const handleReq = () => {
        axios.get(BaseUrl+'user/getdata',{
            params:{
                imei:props.imei
            }
            })
            .then((res) => {
                let temp=[];
                res.data.data.reverse().map((item,i)=>{
                    let time=item.date_time.substring(item.date_time.indexOf("T")+1,item.date_time.indexOf(".")).split(":")
                    let hours =time[0].length==1?(time[0]==0?"12":"0"+time[0]):(parseInt(time[0])%12)>9?parseInt(time[0])%12:parseInt(time[0])%12==0?"12":"0"+parseInt(time[0])%12;
                    let minute=time[1].length==1?"0"+time[1]:time[1]
                    let sec=time[2].length==1?"0"+time[2]:time[2]
                    let ampm =parseInt(time[0])>11?" PM":" AM"
                    time=`${hours}:${minute}${ampm}`
                    let date=item.date_time.split("T")[0].split("-")
                    date= date[2]+"/"+date[1]+"/"+date[0];
                    const dataFields = JSON.parse(item.data_fields);
                    const flowRate = dataFields[Object.keys(dataFields)[0]];
                    
                    const lastValue = res.data.data[res.data.data.length-1];
                    const totalFlow = JSON.parse(lastValue.data_fields);
                    setTotal(totalFlow[Object.keys(totalFlow)[1]]);
                    temp.push({
                        "flow rate":parseFloat(flowRate),
                        "date_time":time,
                        "live":item.live_sts,
                        "date":date
                    })
                   
                   setCurrent(temp[temp.length-1]["flow rate"]);
                })
                setData(temp);
               
                

            })
            .catch((err) => {
                console.log(err)
            })
    }

    React.useEffect(() => {
        handleReq();
        const k = setInterval(handleReq, 15 * 1000);
        return () => {
            clearInterval(k)
        }
    }, [])



    
    

    return (
        <React.Fragment >
            <Button style={{ margin: 20, position: 'absolute', top: '10%', left: '5%', zIndex: 1 }} onClick={props.closeGraph} variant="contained" color='primary'>{'Back'}</Button>
            <Grid container style={{ marginTop: '100px',justifyContent:"center" }} >
                <Grid item xs={12} md={1}>
                    <div style={{ width: '300px', height: '300px', position: 'relative', marginTop: '50px' }} >
                        {/* <div style={{
                            backgroundColor: '#bbb',
                            borderRadius: 25,
                            width: 50,
                            height: 50,
                            position: 'absolute',
                            top: 50,
                            left: '50%',
                            transform: 'translate(-50%)',
                            paddingTop: 12
                        }}>
                            <Typography style={{fontSize:12}} align='center'>{current}</Typography>
                        </div> */}

                    </div>
                    {/* <div style={{ textAlign: 'center', marginLeft: 20, display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <Typography style={{ border: 'solid 1px grey' }} variant="h6" >IMEI:</Typography>
                            <Typography style={{ border: 'solid 1px grey' }} variant="h6" >Total:</Typography>
                        </div>
                        <div>
                            <Typography style={{ border: 'solid 1px grey' }} variant="h6" >{props.imei}</Typography>
                            <Typography style={{ border: 'solid 1px grey' }} variant="h6" >{total}</Typography>
                        </div>
                    </div> */}
                </Grid>
                <Grid item xs={12} md={9}>
                    <ResponsiveContainer >
                        <AreaChart data={data} >
                            <XAxis height={90} angle={90} textAnchor="start" dataKey="date_time"stroke={theme.palette.text.secondary} />
                            <YAxis  stroke={theme.palette.text.secondary} />
                            <Tooltip content={<CustomTooltip />}/>
                            <Area dataKey="flow rate" type="monotone" strokeWidth={3} stroke="#8884d8" fill="#8884d8" dot={(props)=><circle cx={props.cx} cy={props.cy} r={3} stroke="#3F51B5" strokeWidth={2} fill="white" />}/>
                            {/* <Line type="monotone" dataKey="flow rate" strokeWidth={3} stroke={theme.palette.primary.main}  dot={false} /> */}
                        </AreaChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12} md={1}>
                    <div style={{marginTop:'-36px',display:"flex",justifyContent:"center",alignContent:"center"}} >
                        <span>Signal :</span><span style={{marginTop:'0px'}} >{props.signal?props.signal:"N/A"}</span>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment >
    );
}