import React from "react";
import { TextField, Typography, Button } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { BaseUrl } from "../../Constants";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  addmargin: {
    marginTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#37698E",
    "&:hover": {
      backgroundColor: "#37698E",
    },
  },
}))(Button);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddDevices = () => {
  const [device, setDevice] = React.useState("");
  const [imei, setImei] = React.useState("");
  
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    
  }, []);

  const handleClick = () => {
    setError("");
    if (!imei || !device) {
      setError("Please fill all fields");
    } else {
      let user_id = localStorage.getItem("user_id");
      if (user_id) {
            axios
              .post(BaseUrl+"admin/adddevice", {
                user_id,
                imei,
                device_name: device,
              })
              .then(function (res) {
                if (res.data.status) {
                  setOpen(true);
                  setDevice("");
                  setImei("");
                } else {
                  setError("Already Exists")
                }
              })
              .catch(function (error) {
                setError("Check Inputs")
              });
      }
    }
  };


  return (
    <div>
      <div className={classes.toolbar} />
      <Typography variant="h4">Add Device</Typography>
      <div>
        {loading ? (
          <div style={{ flex: 1, padding: 20 }}>
            <TextField
              className={classes.addmargin}
              label="Device Name*"
              value={device}
              onChange={(e) => {
                setDevice(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.addmargin}
              label="IMEI*"
              value={imei}
              onChange={(e) => {
                setImei(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            

            {/* <Autocomplete
              id="combo-box-demo"
              freeSolo
              onInputChange={(e, v) => {
                handleChange(v);
              }}
              value={input}
              options={result.map((option) => option.email)}
              className={classes.addmargin}
              renderInput={(params) => (
                <TextField {...params} label="User Email" variant="outlined" />
              )}
            /> */}
            <Typography color="error">{error}</Typography>
            <ColorButton
              className={classes.addmargin}
              variant="contained"
              onClick={handleClick}
              type="submit"
            >
              Submit
            </ColorButton>
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={() => setOpen(false)}
            >
              <Alert onClose={() => setOpen(false)} severity="success">
                Device Added
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <div>Loading Failed</div>
        )}
      </div>
    </div>
  );
};
export default AddDevices;
