import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Admin from '../../component/Admin/Admin';
import LeftDrawer from '../AdminPanel/LeftDrawer'
import axios from 'axios';
import AOS from "aos";
import "aos/dist/aos.css";
import '../../image/logo.png';
import { BaseUrl } from '../../Constants';
import { Modal } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

AOS.init();

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundColor: '#292e3a',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    paper: {
        // margin: theme.spacing(8, 4),
        padding: '60px 30px 30px 30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: '15px',
    },
    textName: {
        // display: "block",
        color: 'white',
        fontSize: '40px',
    },
    subTextName: {
        marginLeft: '130px',
        color: '#BBB',
        fontSize: '25px',
        lineHeight: '0px'
    },
    quoteAndLogo: {
        display: 'flex',
        alignItems: 'center'
    },
    paper1: {
        position: 'absolute',
        width: 800,
        backgroundColor: "white",
        border: '2px solid #000',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        padding:20
      },
      addmargin: {
        marginTop: 20,
      },
}));
const ColorButton = withStyles((theme) => ({
    root: {
        background: '#34658E',
        borderRadius: 10,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        '&:hover': {
            backgroundColor: '#34658E',
        },
    },
    label: {
        textTransform: 'capitalize',
    },
}))(Button);
export default function Login(props) {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        email: '',
        password: '',
        showPassword: false,

    });
    const [error, setError] = React.useState("");
    const [open, setOpen] = React.useState(false)
    const [email, setEmail] = React.useState("");
    const [open1,setOpen1] = React.useState(false);
    const [open2,setOpen2] = React.useState(false);


    React.useEffect(() => {
        if (localStorage.getItem("user_id")) {
          if(localStorage.getItem("role")==='ADMIN')
          setOpen(2);
          else
          setOpen(1);
        } else {
          setOpen(0);
          localStorage.clear("user_id");
        }
      }, []);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleLogin = (e) => {
        e.preventDefault();
        const {email,password}=values;
        setError("");
        if (!email || !password) setError("Field Empty");
        else {
          axios
            .post(BaseUrl+"auth/login", { email, password })
            .then(function (res) {
              if (res.data.status) {
                localStorage.setItem("user_id", res.data.user.user_id);
                localStorage.setItem("role", res.data.user.role);
                console.log(res.data.user)
                if(res.data.user.role==="ADMIN")
                setOpen(2);
                else
                setOpen(1);
              } else setError(res.data.message);
            })
            .catch(function (error) {
              setError("Invalid Email/Password");
            });
        }
    }
    const handleLogout = () => {
        setOpen(0);
        localStorage.clear('user_id')
        setValues({
            email: '',
            password: ''
        })
    }
    React.useEffect(() => {
        if (localStorage.getItem('user')){
            if(localStorage.getItem('user')==='ADMIN')
            setOpen(1);
        }
            
    }, [])


    const handleClick = () => {
        setError("");
        if ( !email  ) {
          setError("Please fill all fields");
        } else if (!email.includes("@") || !email.includes(".")) {
          setError("Enter valid email");
        } else {
            axios
              .post(BaseUrl+"auth/forgotpass", {
                email
              })
              .then(function (res) {
                if (res.data.status) {
                  setEmail("")
                  setOpen2(true);
                  setOpen1(false);
                  
                } 
              })
              .catch(function (error) {
                console.log(error);
              });
          
        }
      };
    return (
        <div>
            {!open ?
                <Grid container component="main" className={classes.root}>
                    <CssBaseline />
                    <Grid item xs={12} sm={7} md={8} className={classes.image} >
                        <div style={{ height: '150px', margin: 'auto' }}>
                            <div className={classes.quoteAndLogo} >
                                <img src={require('../../image/logo.png')} style={{ maxWidth: '120px' }} alt="logo"></img>
                                <div>
                                    <Typography className={classes.textName}>
                                        Silicis Road Industries LLP
                                    </Typography>
                                    <Typography style={{ fontSize: '15px', color: 'white', lineHeight: '0', float: 'right' }}>
                                        DataLogger
                                    </Typography>
                                </div>
                            </div>
                            <Typography className={classes.subTextName}>
                                -making the heart of electronics
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item style={{ backgroundColor: '#292e3a' }} xs={12} sm={5} md={4} component={Paper} elevation={0}>
                        <div style={{ backgroundColor: 'white', height: '100%' }} data-aos="fade-down" data-aos-duration="1000">
                            <div className={classes.paper}>
                                <Typography style={{ color: '#34658E' }} component="h1" variant="h4">
                                    login
                                </Typography>
                                <form className={classes.form} noValidate>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        onChange={handleChange('email')}
                                        label="Email Address"
                                        name="email"
                                        autoComplete='off'
                                        autoFocus
                                    />
                                    <FormControl fullWidth variant="outlined" >
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                        <Typography color="error">{error}</Typography>
                                    </FormControl>
                                    
                                    <ColorButton
                                        fullWidth
                                        type="submit"
                                        onClick={handleLogin}
                                        color="primary"
                                        variant="contained"
                                        className={classes.submit}
                                    >
                                        Sign In
                                    </ColorButton>
                                    <Grid container>
                                        <Grid item xs>
                                            <Link onClick={()=>{setOpen1(true)}} variant="body2">
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </Grid>
    
                </Grid> :
                (open===1?
                <Admin logout={handleLogout} />
                :<LeftDrawer logout={handleLogout}/>
                )
            }

    <Modal
        open={open1}
        onClose={()=>setOpen1(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper1}>
          <h2 id="simple-modal-title">Reset Password</h2>
          <TextField
              className={classes.addmargin}
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <Typography color="error">{error}</Typography>
            <ColorButton
              className={classes.addmargin}
              variant="contained"
              onClick={handleClick}
              type="submit"
            >
              Submit
            </ColorButton>
            
        </div>
      </Modal>
      <Snackbar
              open={open2}
              autoHideDuration={5000}
              onClose={() => setOpen2(false)}
            >
              <Alert onClose={() => setOpen2(false)} severity="success">
                Please Check Your Inbox!
              </Alert>
      </Snackbar>
        </div>
    );
}