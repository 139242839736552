import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TableList from "./Table/table";
import History from "./History/History";
import Graph from "./Graph/graph";
import axios from "axios";
import { BaseUrl } from "../../Constants";
import signal_no from "../../image/no.jpg";
import signal_1 from "../../image/1.jpg";
import signal_2 from "../../image/2.jpg";
import signal_3 from "../../image/3.jpg";
import signal_4 from "../../image/4.jpg";
import { Tooltip } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import AnalogueClock from 'react-analogue-clock';
import Clock from "react-digital-clock";

const ColorButton = withStyles((theme) => ({
  root: {
    background: "#34658E",
    borderRadius: 10,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    "&:hover": {
      backgroundColor: "#34658E",
    },
  },
  label: {
    textTransform: "capitalize",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#37698E",
  },
  title: {
    fontSize: "30px",
    fontWeight: "600",
  },
  subtitle: {
    fontSize: "14px",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  borders: {
    border: "solid grey 1px",
    padding: 5,
  },
  theadcell:{padding:'10px 20px 10px 20px !important'
  },
  custDetails:{
    padding:'5px'
  }
}));

function Admin(props) {
  const classes = useStyles();
  let signalArr = [signal_no, signal_1, signal_2, signal_3, signal_4];
  const [open, setOpen] = React.useState(false);
  const [customer, setCustomer] = React.useState("Loading");
  const [client, setClient] = React.useState("Loading");
  const [project, setProject] = React.useState("Loading");
  const [totaldevices, setTotaldevices] = React.useState("Loading");
  const [imei, setImei] = React.useState("");
  const [current, setCurrent] = React.useState(0);
  const [rows, setRows] = React.useState([]);

  const handleGraph = (imei, c) => {
    setImei(imei);
    setCurrent(c);
    setOpen(1);
  };
  const closeGraph = () => {
    setOpen(0);
  };
  function createData(device, location, serial, imei, signal) {
    return { device, location, signal, serial, imei };
  }
  const handleHistory = (imei) => {
    setImei(imei);
    setOpen(2);
  };

  const getSignal = (arr) => {
    let user_id = localStorage.getItem("user_id");

    axios
      .get(BaseUrl + "user/getsignal", { params: { user_id } })
      .then(function (res) {
        if (res.data.status) {
          console.log("data:", res.data);
          let temp = [];
          arr.map((item, i) => {
            let signal = "Not Available";
            res.data.devices.map((device) => {
              if (device.IMEI == item.imei) {
                let temp = Math.floor((4 / 31) * device.dev_signal);
                signal = (
                  <Tooltip
                    title={
                      device.dev_signal == 99 ? (
                        <div>Unknown Network</div>
                      ) : (
                        device.dev_signal
                      )
                    }
                  >
                    {device.dev_signal == 99 ? (
                      <div>Unknown Network</div>
                    ) : (
                      <img
                        src={signalArr[temp]}
                        alt={i}
                        style={{ width: 20, height: 20 }}
                      />
                    )}
                  </Tooltip>
                );
              }
            });

            temp.push(
              createData(
                item.device_name,
                item.location,
                item.imei,
                item.imei,
                signal
              )
            );
          });
          console.log("temp", temp);
          setRows(temp);
        }
      })
      .catch(function (error) {
        console.log(error);
        let temp = [];
        arr.map((item, i) => {
          temp.push(
            createData(
              item.device_name,
              item.location,
              "01826838",
              item.imei,
              "N/A"
            )
          );
        });
        setRows(temp);
      });
  };

  React.useEffect(() => {
    let id = localStorage.getItem("user_id");
    let k;
    axios
      .post(BaseUrl + "user/getuserbyid", { id })
      .then(function (res) {
        if (res.data.status) {
          console.log(res.data);
          setCustomer(res.data.user.customer_name);
          setClient(res.data.user.client_name);
          setTotaldevices(res.data.user.device_count);
          setProject(res.data.user.project_name);
          console.log("devices", res.data.user.devices);
          getSignal(res.data.user.devices);
          k = setInterval(() => {
            getSignal(res.data.user.devices);
            console.log("test");
          }, 1000 * 10);
        }
      })
      .catch(function (error) {});
    return () => {
      clearInterval(k);
    };
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div>
            <Typography
              component="h1"
              variant="h4"
              color="inherit"
              className={classes.title}
            >
              mydatahub.in
            </Typography>

            <Typography
              className={classes.subtitle}
              component="h1"
              variant="h6"
              color="inherit"
              style={{ float: "right" }}
            >
              Advanced Data Monitoring System
            </Typography>
          </div>

          <div>
            <Typography
              component="h3"
              variant="h5"
              color="inherit"
              style={{ float: "right" }}
            >
              {customer}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        {!open ? (
          <div>
            <div className={classes.appBarSpacer} />
            <Container>
              <TableContainer component={Paper} elevation={24} style={{marginTop:'30px'}}>
              <Table size="medium">
                <TableHead >
                  <TableRow>
                    <TableCell className={classes.theadcell} >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="h4"
                          color="inherit"
                        >
                          Customer Details
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              background: "#000154",
                              height: 40,
                              display: "flex",
                              alignItems: "center",
                              marginRight: 20,
                              padding: 20,
                              borderRadius: 5,
                            }}
                          >
                            <Clock hour12={false} />
                          </span>

                          <ColorButton
                            onClick={props.logout}
                            color="primary"
                            variant="contained"
                          >
                            Logout
                          </ColorButton>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  <TableRow style={{borderBottom: '1px solid #e0e0e0'}}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Grid container style={{padding:'20px'}}>
                          <Grid item xs={12} className={classes.custDetails}>
                          CLIENT NAME :   <b> {client}</b>
                            </Grid>
                            <Grid item xs={12} className={classes.custDetails}>
                            PROJECT NAME  :   <b> {project}</b>
                            </Grid>
                            <Grid item xs={12} className={classes.custDetails}>
                            TOTAL DEVICES :   <b> {totaldevices}</b>
                            </Grid>   
                        </Grid>
                    </div>
                  </TableRow>
                  <TableRow>
                <Grid container>
                  <Grid item xs={12}>
                      <TableList
                        handleGraph={handleGraph}
                        handleHistory={handleHistory}
                        rows={rows}
                      />
                  </Grid>
                </Grid>          
                  </TableRow>
                </TableBody>
              </Table>
              </TableContainer>
             
            </Container>
          </div>
        ) : open == 1 ? (
          <div>
            <div className={classes.appBarSpacer} />
            <div style={{ height: "350px" }}>
              <Graph
                closeGraph={closeGraph}
                imei={imei}
                signal={rows[current]?.signal}
              />
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className={classes.appBarSpacer} />
              <div style={{ height: "300px", width: "80%" }}>
                <History closeGraph={closeGraph} imei={imei} />
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
export default Admin;
