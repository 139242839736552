import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import EditIcon from '@material-ui/icons/Edit';
import axios from "axios";
import { BaseUrl } from "../../Constants";
import { Button, Modal, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    maxWidth: 100,
    wordWrap: "break-word",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#37698E",
    "&:hover": {
      backgroundColor: "#37698E",
    },
  },
}))(Button);

const useStyles = makeStyles({
  table: {},
  tableContainer: {
    marginTop: 80,
  },
  paper: {
    position: 'absolute',
    width: 800,
    backgroundColor: "white",
    border: '2px solid #000',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    padding:20
  },
  addmargin: {
    marginTop: 20,
  },
});

export default function Users() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [id, setId] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [project, setProject] = React.useState("");
  const [customer, setCustomer] = React.useState("");
  const [error, setError] = React.useState("");
  const [total, setTotal] = React.useState("");
  const [deactivated, setDeactivated] = React.useState("");

  const deleteRow = (id,active) => {
    if (window.confirm("are you sure?")) {
      let user_id = localStorage.getItem("user_id");
      axios
        .post(BaseUrl+"admin/activeuser", {
            id,active,user_id

        })
        .then(function (res) {
          if (res.data.status) {
            getData();
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  };

  const handleEdit = (uid,uname,uemail,uphone,uproject,ucustomer) => {
    setId(uid)
    setName(uname);
    setEmail(uemail);
    setPhone(uphone);
    setProject(uproject);
    setCustomer(ucustomer);
    setOpen(true)
  }
  const handleClick = () => {
    setError("");
    if (!name || !email  || !phone) {
      setError("Please fill all fields");
    } else if (!email.includes("@") || !email.includes(".")) {
      setError("Enter valid email");
    } else {
      let user_id = localStorage.getItem("user_id");
        axios
          .post(BaseUrl+"admin/edituser", {
            user_id,
            id,
            email,
            client_name: name,
            phone,
            project_name: project,
            customer_name: customer,
            expiry: "N/A",
          })
          .then(function (res) {
            if (res.data.status) {
              setName("");
              setPhone("");
              setProject("");
              setCustomer("");
              setEmail("")
              setOpen1(true);
              
              setOpen(false);
              getData();
              
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      
    }
  };

  const getData = () => {
    let user_id = localStorage.getItem("user_id");
    if (user_id) {
      axios
        .get(BaseUrl+"admin/getusers", {
          params: { user_id },
        })
        .then(function (res) {
          if (res.data.status) {
            console.log("users",res.data.users)
            setRows(res.data.users);
            setTotal(res.data.users.length)
            let count=0;
            res.data.users.map(item=>item.isActive==1?null:count++)
            setDeactivated(count)
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  React.useEffect(() => {
    getData();
  }, []);


  return (
    <div className={classes.tableContainer}>
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
      <Typography variant="h4" style={{ marginBottom: 20 }}>
        Users
      </Typography>
      <div>
        <h5 style={{marginRight:50}}>Total Users: {total}</h5>
        <h5 style={{marginRight:50}}>Deactivated Users: {deactivated}</h5>
      </div>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Client Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Project Name</StyledTableCell>
              <StyledTableCell>Customer Name</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell>{row.client_name}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>{row.phone}</StyledTableCell>
                <StyledTableCell>{row.project_name}</StyledTableCell>
                <StyledTableCell>{row.customer_name}</StyledTableCell>
                <StyledTableCell
                >
                  <span style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                    <Tooltip style={{marginRight:10,cursor:"pointer"}} title="edit">
                      <EditIcon onClick={()=>{handleEdit(row.user_id,row.client_name,row.email,row.phone,row.project_name,row.customer_name)}} color="primary" />
                    </Tooltip>
                    {row.isActive?
                    <Button color="secondary" variant="outlined" onClick={()=>deleteRow(row.user_id,0)}>Deactivate</Button>
                    :<Button color="primary" variant="outlined" onClick={()=>deleteRow(row.user_id,1)}>Activate</Button>
                  }
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {!rows.length&&<StyledTableRow style={{width:"100%"}}><div style={{margin:10,textAlign:"center"}}>No Users Found</div></StyledTableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2 id="simple-modal-title">Edit User Info</h2>
          <TextField
              className={classes.addmargin}
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.addmargin}
              label="Client Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.addmargin}
              label="Phone No."
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.addmargin}
              label="Project Name(Optional)"
              value={project}
              onChange={(e) => {
                setProject(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <TextField
              className={classes.addmargin}
              label="Customer Name(Optional)"
              value={customer}
              onChange={(e) => {
                setCustomer(e.target.value);
              }}
              variant="outlined"
              fullWidth
            />
            <Typography color="error">{error}</Typography>
            <ColorButton
              className={classes.addmargin}
              variant="contained"
              onClick={handleClick}
              type="submit"
            >
              Submit
            </ColorButton>
            
        </div>
      </Modal>
      <Snackbar
              open={open1}
              autoHideDuration={2000}
              onClose={() => setOpen1(false)}
            >
              <Alert onClose={() => setOpen1(false)} severity="success">
                Account Edited
              </Alert>
      </Snackbar>
    </div>
  );
}
