import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles((theme) => ({
    thead: {
        backgroundColor: '#145F97',
        color: 'white',
    },
    theadcell: {
        color: 'white',
        padding: '10px',
        paddingRight:'25px'
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));


function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

export default function TableList(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    
    return (
        <React.Fragment>
            <Table size="medium">
                <TableHead className={classes.thead}>
                    <TableRow>
                        <TableCell className={classes.theadcell}>Device Name</TableCell>
                        <TableCell className={classes.theadcell}>Location</TableCell>
                        <TableCell className={classes.theadcell}>Signal</TableCell>
                        <TableCell className={classes.theadcell}>IMEI</TableCell>
                        <TableCell  align="right" className={classes.theadcell}>Live Data</TableCell>
                        <TableCell  align="right" className={classes.theadcell}>History Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row, index) => (
                        <TableRow key={row.imei}>
                            <TableCell>{row.device}</TableCell>
                            <TableCell>{row.location}</TableCell>
                            <TableCell>{row.signal}</TableCell>
                            <TableCell>{row.serial}</TableCell>
                            <TableCell  align="right">{ <Button  onClick={()=>props.handleGraph(row.imei,index)} variant="contained"   style={{ textTransform: 'none' }}><img alt="live-data" src={require('../../../image/liveData.png')} style={{marginRight:'4px'}} /> Live </Button> }</TableCell>
                            <TableCell  align="right">{ <Button  color="success" onClick={() =>props.handleHistory(row.imei)} variant="contained" style={{ textTransform: 'none' }}><img alt="historical-data" src={require('../../../image/historical.png')}  style={{marginRight:'4px'}} /> History </Button> }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={10}
                        count={props.rows.length}
                        rowsPerPage={15}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                </TableFooter>
            </Table>
        </React.Fragment >
    );
}