import React from 'react';
import Login from './component/login/login';
function App() {

  return (

    <div>
      <Login />

      {/* <Admin /> */}
    </div>
  );
}

export default App;
